import ClassicExtension from "../../ClassicExtension";

/**
 * When scrollbar is out of view move it to the bottom of screen
 *  - apply to .table-responsive
 *
 *  @see https://jsfiddle.net/8zoks7wz
 */
export default class RemoteHorizontalScrollbar extends ClassicExtension {
    attach($root: JQuery) {
        const fixedBarTemplate = '<div class="fixed-scrollbar"><div></div></div>';
        const fixedBarCSS = {
            display: 'none',
            overflowX: 'auto',
            position: 'fixed',
            width: '100%',
            bottom: 0,
            zIndex: 9,
        };

        $root.find('.table-responsive').each((i,el) => {

            const $container = $(el);
            const $bar = $(fixedBarTemplate).appendTo($container).css(fixedBarCSS);

            $bar.on( 'scroll', () => $container.scrollLeft($bar.scrollLeft()) );
            $container.on( 'scroll', () => $bar.scrollLeft($container.scrollLeft()) );
            $bar.data("horizontal-scrollbar-status", "off");

        });

        $(window).on("load.fixedbar resize.fixedbar", () => {
            this.fixSize();
        });

        let scrollTimeout = null;

        $(window).on("scroll.fixedbar", () => {
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                $('.table-responsive').each((i, el)=> {
                    const $container = $(el);
                    const $bar = $container.children('.fixed-scrollbar');

                    if ($bar.length) {
                        const containerOffset = {top: $container.offset().top, bottom: $container.offset().top + $container.height() };
                        const windowOffset = {top: $(window).scrollTop(), bottom: $(window).scrollTop() + $(window).height() };

                        if (containerOffset.top > windowOffset.bottom || windowOffset.bottom > containerOffset.bottom) {
                            if ($bar.data("horizontal-scrollbar-status") === "on") {
                                $bar.hide(0).data("horizontal-scrollbar-status", "off");
                            }
                        } else {
                            if ($bar.data("horizontal-scrollbar-status") === "off") {
                                $bar.show(0).data("horizontal-scrollbar-status", "on");
                                $bar.scrollLeft($container.scrollLeft());
                            }
                        }
                    }
                });
                this.fixSize();
            }, 50);
        });

        $(window).trigger("scroll.fixedbar");
    }

    private fixSize(): void {
        $('.fixed-scrollbar').each((i,el) => {
            const $bar = $(el);
            const $container = $bar.parent();

            $bar.children('div')
                .height(1)
                .width($container[0].scrollWidth);

            $bar.width($container.width())
                .scrollLeft($container.scrollLeft());
        });
    }
}
